import { useState, useContext, useRef } from "react";
import "./login.css";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../context/AuthContext";

export default function Login() {
  const clientNr = useRef();
  const gwokuToken = useRef();


  const email = useRef();
  const password = useRef();
  const { isFetching, dispatch } = useContext(AuthContext);

  const handleClick = (e) => {
    e.preventDefault();

    loginCall(
      {
        email: email.current.value,
        password: password.current.value,
      },
      dispatch
    );
  };

  return (
    <div className="login">
      <div>
        <div className="loginWrapper">
          <div className="loginLogo">
            <img src="assets/ColorBg.png" alt="" className="loginLogoImg" />
          </div>

          <form className="loginBox" onSubmit={handleClick}>
            <div className="logintop">
              <h1> Welcome!</h1>
            </div>
            <div className="logintop">
              <p>Log in your account</p>
            </div>
            <br></br>
            {/* <label>Client ID</label>
          <input
              type="password"
              required
              ref={clientNr}
              className="loginInput"
            /> */}
            <label>Email</label>
            <input
              type="email"
              name="email"
              required
              className="loginInput"
              id="emailInput"
              ref={email}
            />
            <label>Password</label>
            <input
              type="password"
              name="password"
              required
              minLength="6"
              className="loginInput"
              ref={password}
            />
            <div className="loginBottom">
              <label className="rememberMe">
                <input
                  type="checkbox"
                  id="rememberMeCheckBock"
                  defaultChecked
                />{" "}
                Remember me
              </label>
              <label className="loginForgot">
                <a href="/forgot-password">Forgot Your Password?</a>
              </label>
            </div>
            <button className="loginButton" type="submit">
              Log In
            </button>
            <br></br>
            <div></div>
          </form>
        </div>
      </div>
    </div>
  );
}
