import "./sidebar.css";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useAlert } from "../../context/AlertContext";

export default function Sidebar() {
  const { user, token } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Este es opcional dependiendo de tus necesidades
    },
  };

  const { showAlert } = useAlert();
  const clientNr = user.clientNr;

  const [users, setUsers] = useState([]);

  var originalbody = {
    clientNr: clientNr,
    chatbotKey: user.chatbotKey,
  };

  const body = originalbody;
  useEffect(async () => {
    const myresult = await axios
      .post(
        process.env.REACT_APP_CENTRAL_BACK + "/users/queryall",
        body,
        config
      )
      .catch((err) => {
        console.log(err);
        showAlert("Error in users request", err.response.data.status);
        return;
      });
    const result = myresult.data;
    setUsers(
      result.sort((p1, p2) => {
        return new Date(p2.createdAt) - new Date(p1.createdAt);
      })
    );
  }, []);

  return (
    <div className="sidebar">
      <div className="logoContainer pt-3">
        <img src="/assets/Logo_Header.png" className="logo"></img>
      </div>
      <div className="sidebarWrapper">
        <ul className="sidebarList">
          <li className="sidebarListItem">
            <div
              className="sidebarOption"
              style={{ textDecoration: "none", color: "#1E1E1E" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 20 14"
                fill="none"
              >
                <path
                  d="M16.6666 3.66732H14.1666V0.333984H2.49992C1.58325 0.333984 0.833252 1.08398 0.833252 2.00065V11.1673H2.49992C2.49992 12.5507 3.61659 13.6673 4.99992 13.6673C6.38325 13.6673 7.49992 12.5507 7.49992 11.1673H12.4999C12.4999 12.5507 13.6166 13.6673 14.9999 13.6673C16.3833 13.6673 17.4999 12.5507 17.4999 11.1673H19.1666V7.00065L16.6666 3.66732ZM16.2499 4.91732L17.8832 7.00065H14.1666V4.91732H16.2499ZM4.99992 12.0007C4.54158 12.0007 4.16658 11.6257 4.16658 11.1673C4.16658 10.709 4.54158 10.334 4.99992 10.334C5.45825 10.334 5.83325 10.709 5.83325 11.1673C5.83325 11.6257 5.45825 12.0007 4.99992 12.0007ZM6.84992 9.50065C6.39158 8.99232 5.74158 8.66732 4.99992 8.66732C4.25825 8.66732 3.60825 8.99232 3.14992 9.50065H2.49992V2.00065H12.4999V9.50065H6.84992ZM14.9999 12.0007C14.5416 12.0007 14.1666 11.6257 14.1666 11.1673C14.1666 10.709 14.5416 10.334 14.9999 10.334C15.4583 10.334 15.8333 10.709 15.8333 11.1673C15.8333 11.6257 15.4583 12.0007 14.9999 12.0007Z"
                  fill="#1B3633"
                />
              </svg>
              <span className="truncate">Routing</span>
            </div>
          </li>
          <li id="KMLBullet" className="sidebarListItem">
            <div
              className="sidebarOption font-medium"
              style={{ textDecoration: "none", color: "#1E1E1E" }}
            >
              <img src="/assets/cartpng.png" className="sidebarIconInvisible" />
              <span>KML</span>
            </div>
          </li>
        </ul>

        <div className="lowbarRight">
          <div className="lowbarLinks"></div>
        </div>
      </div>
    </div>
  );
}
