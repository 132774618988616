import { useContext, useEffect, useState } from "react";
import Post from "../post/Post";
import Share from "../share/Share";
import "./feed.css";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Topbar from "../../components/topbar/Topbar";
import Alert from "../alert/Alert";
import NoMaps from "../noMaps/noMaps";
import IncompleteMap from "../incompleteMap/IncompleteMap.jsx";
import { useAlert } from "../../context/AlertContext";

export default function Feed({ username }) {
  const [maps, setMaps] = useState(null);
  const { user, token} = useContext(AuthContext);
  const clientNr = user.clientNr;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Este es opcional dependiendo de tus necesidades
    },
  };

  const { showAlert } = useAlert();
  // get the chatbot master of the chatbot created for this user

  useEffect(() => {
    const fetchMaps = async () => {
      if (!username) {
        var originalbody = {
          clientNr: clientNr,
        };
        const body = originalbody;
        // query all the bots
        
        const response = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/maps/queryall/",
          body, config
        ).catch((err) => {
          showAlert("Error in maps request", err.response.data.status)
          if (err.response.data.message === "Token inválido") {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.location.reload();
          }
          return;
        });
        if (response === undefined) {
          return;
        }
        const res = response.data;
        setMaps(
          res.sort((p1, p2) => {
            // res.data.sort((p1, p2) => {
            return new Date(p2.createdAt) - new Date(p1.createdAt);
          })
        );
      }
    };
    fetchMaps();
  }, [username]);

  return (
    <>
      <Alert />

      <div className="feed">
        <Topbar />

        <div className="feedWrapper">
          {maps === null ? (
            <p>Loading...</p>
          ) : maps.length === 0 ? (
            <NoMaps />
          ) : (
            maps.map((map) =>
              map.updateStatus !== "complete" ? (
                <IncompleteMap key={map._id} updateStatus={map.updateStatus} />
              ) : (
                <Post key={map._id} post={map} />
              )
            )
          )}
        </div>
      </div>
    </>
  );
}
