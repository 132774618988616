import React, { useState, useEffect } from "react";
import "./uploadModal.css";

export default function UploadModal({ setFilename, confirmUpload, filename, setConfirmationOpen, confirmationOpen, setFiles, onClose }) {

  const fileInputRef = React.createRef();
  const [uploaded, setUploaded] = useState();

  useEffect(() => {
    console.log(uploaded)
    if (!uploaded) return;

  }, [uploaded]);


  const handleFileUpload = () => {
    
    const selectedFiles = fileInputRef.current.files;

    if (selectedFiles.length > 0) {
      const selectedFilename = selectedFiles[0].name;
      setFilename(selectedFilename);
      // Display confirmation dialog before uploading
      setConfirmationOpen(true);
      // Set the selected files in the state
      setFiles(selectedFiles);
    } else {
      return;
    }
  };

  const handleButtonUpload = () => {
    if (!uploaded) return;
    setUploaded(false);confirmUpload();onClose();
  
  }

  
  return (
    <div className="modalDialog">
        <div className="top">
          <div className="left">Upload KML</div>
          <div className="close" onClick={()=>{onClose(); setUploaded(false)}}>
            &times;
          </div>
        </div>
         {confirmationOpen && (
          <div >
          <p className="text-center text-xl pb-4">
            <b>Are you sure?</b>
          </p>
          <p className="text-center font-normal ">
          This will reassign existing customers to a new area if the boundaries of their existing assignment has been updated. This update can take up to 2 hours.          </p>
        </div>
         )}
        <div className="middle">
         <p className="text-black">KML File</p>
         {uploaded ?
        (
         <div><label  htmlFor="file"><p className="w-full greyed modalFileSearch">{uploaded}</p></label><input
         type="file"
         id="file"
         name="file"
         multiple
         className="modalSearch"
         acceptCharset="utf-8"
         accept=".kml"
         ref={fileInputRef}
         style={{ display: "none" }}
         onChange={()=> {
           handleFileUpload();
           setUploaded(fileInputRef.current.files[0].name)}
         }
       /> </div>
       ) 
           : 
          (<>
          <label htmlFor="file" className="text-gray-400 modalFileSearch flex justify-between ">{filename ? filename : "Search File"}
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M13.8138 12.0598H13.1555L12.9221 11.8348C13.9221 10.6681 14.4388 9.07643 14.1555 7.38477C13.7638 5.0681 11.8305 3.2181 9.49713 2.93477C5.97213 2.50143 3.00546 5.4681 3.43879 8.9931C3.72213 11.3264 5.57213 13.2598 7.88879 13.6514C9.58046 13.9348 11.1721 13.4181 12.3388 12.4181L12.5638 12.6514V13.3098L16.1055 16.8514C16.4471 17.1931 17.0055 17.1931 17.3471 16.8514C17.6888 16.5098 17.6888 15.9514 17.3471 15.6098L13.8138 12.0598ZM8.81379 12.0598C6.73879 12.0598 5.06379 10.3848 5.06379 8.30977C5.06379 6.23477 6.73879 4.55977 8.81379 4.55977C10.8888 4.55977 12.5638 6.23477 12.5638 8.30977C12.5638 10.3848 10.8888 12.0598 8.81379 12.0598Z" fill="#77777D"/>
</svg>
          </label>
        <div className="search-bar">
        <input
            type="file"
            id="file"
            name="file"
            multiple
            className="modalSearch"
            acceptCharset="utf-8"
            accept=".kml"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={()=> {
              handleFileUpload();
              setUploaded(fileInputRef.current.files[0].name)}
            }
          />
          
        </div>
        
          
          </>)
        }
        </div>
       
        <div className="modalDialog-buttons">
        <button className="modalDownloadButton" onClick={handleButtonUpload}>
              Upload
            </button>
          <button className="modalcancelbutton" onClick={()=>{onClose(); setUploaded(false)}}>
            Cancel
          </button>
        </div>
      </div>
  );
}
