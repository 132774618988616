import { useEffect } from 'react';
import { useAlert } from '../../context/AlertContext'; // adjust the path as necessary

function Alert() {
  const { alert, hideAlert } = useAlert();

  useEffect(() => {
  }, [alert]);

  if (!alert) return null;

  let alertClasses = "";
  let iconClasses = "";
  let lineColor = "";
  if (alert.type === 'error') {
    alertClasses = "bg-red-100 text-red-500";
    iconClasses = "text-red-500";
    lineColor = "bg-red-500";
  } else if (alert.type === 'success') {
    alertClasses = "bg-green-100 text-green-500";
    iconClasses = "text-green-500";
    lineColor = "bg-green-500";
  }

  return (
    <div className={`${alertClasses} pl-5 pr-12 py-3 mr-5 rounded fixed top-20 right-0 w-9/12 z-[10000] shadow-md`} role="alert">
    <strong className="font-light">{alert.message}</strong>
    <span className="absolute top-0 bottom-0 right-0 pl-4 gap-1 flex">
      <svg className={`py-3 fill-current h-6 w-6 ${iconClasses}`} role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={hideAlert}><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
      <div className={`${lineColor} h-full w-2 rounded-r`}></div>
    </span>
  </div>
  );
}

export default Alert;
