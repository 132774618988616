import { useState, createContext, useContext } from 'react';

// Create the context
const AlertContext = createContext();

// This context provider is a component that will wrap your application
export function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  // The value that will be given to the context
  const showAlert = (message, type) => {
    setAlert({ message, type });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
}

// Create a custom hook to use the alert context
export function useAlert() {
  return useContext(AlertContext);
}
