import axios from "axios";

export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {

    const config = {
      headers: {
        "secret": process.env.REACT_APP_SECRET_KEY,
      },
    };

    const body = {
      email: userCredential.email,
      password: userCredential.password,
    };
    const res = await axios.post(
      process.env.REACT_APP_CENTRAL_BACK + "/auth/login",
      body, config
    );


    dispatch({ type: "LOGIN_SUCCESS", payload: {...res.data} });
  } catch (err) {
    alert(err.response.data);
    dispatch({ type: "LOGIN_FAILURE", payload: err });
  }
};
