const crypto = require('crypto-js');
const forge = require('node-forge');

export const downloadCSV = (data, filename) => {
  // Extract headers (keys) from the first row of the data
  const headers = "Customer Number,Name,Street,City,State,Longitude,Latitude\n";

  const csv = headers + data.map((customer) => `${customer.customerNr},"${customer.name}","${customer.street}","${customer.city}","${customer.state}",${customer.longitude},${customer.latitude}`).join('\n');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};



function CalculateSignature(token,parameters)
{
    // calculate the hash value of the token
    console.log("GWOCU TOKEN on first line of CALCULATE SIGNATURE");
    console.log(token);
    console.log("PARAMETER FOR on first line CALCULATE SIGNATURE");
    console.log(parameters);

    
    // var ApiTokenHashvalue = CryptoJS.MD5(str_1 + str_2).toString();
    var ApiTokenHashvalue = crypto.MD5(token).toString();
    
    // order parameters alfabetically
    var SortedParams = sortObjByReverseKey(parameters);
    
    // Concatenate: add '&' between key and value pair and replace : for = 
    var MyString = '' ;
    for (const [key, value] of Object.entries(SortedParams)) {
        MyString += (`${key}=${value}&`);}

    //  add hash value of token at the and of the string
    MyString += ApiTokenHashvalue ;

    // create the verifySign

    const MySignature = crypto.MD5(MyString).toString();

    return MySignature;
    }

    
// algabetical sort helper function
    function sortObjByReverseKey(obj) 
    {
    return Object.keys(obj).sort(function (a, b) {
      return a.split("").reverse().join("").localeCompare(b.split("").reverse().join(""));
    }).reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }
