import React, { useEffect } from 'react'
import { useAlert } from '../../context/AlertContext'; 
import FileUpload from "../fileupload/FileUpload";
import "./noMaps.css";

export default function NoMaps() {

  const { showAlert } = useAlert();
  useEffect(() => {
    showAlert('Please upload a KML file, as there is currently no active file in the system!', 'error');

  }, []);


  return (
    <div className="noMap">
    <div className="noMapWrapper">
      <div className="noMapTop">
        <span className='text-xl'> KML </span>
        </div>
      </div>
      <div className='noMapMiddle'>
            <FileUpload/>
            </div>
      
    </div>

  )
}
