import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { downloadCSV } from "../../utils/utils.js";
import ZoneDetails from "../zoneDetails/ZoneDetails";
import "./post.css";
import { Map, Polygon, GoogleApiWrapper } from "google-maps-react";
import FileUpload from "../../components/fileupload/FileUpload";
import Modal from "../../components/modal/Modal";
import { useAlert } from "../../context/AlertContext";


function Post({ post, google }) {
  const [showZoneDetails, setShowZoneDetails] = useState(false);
  const [selectedZoneName, setSelectedZoneName] = useState("");
  const [zones, setZones] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapPolygons, setMapPolygons] = useState([]); // Array to hold polygons
  const [showModal, setShowModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);

  const { showAlert } = useAlert();
  const { user, token } = useContext(AuthContext);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };


  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK+"/zones/queryall",
        { clientNr: user.clientNr }, config
      ).catch((err) => {
        showAlert("Error in zones request", err.response.data.status)
        return;
      });
      if (response === undefined) {
        return;
      };

      setZones(response.data);

      // Update the map center if there are zones available
      if (response.data.length > 0) {
        const firstZone = response.data[0];
        const coordinates = firstZone.location.coordinates[0][0];
        setMapCenter({ lat: coordinates[1], lng: coordinates[0] });
      }

      const polygons = response.data.map((zone, index) => {
        // Generate a random color for the fill with transparency
        const fillColor = `#${Math.floor(Math.random() * 16777215).toString(16)}88`; // Random color with 50% opacity

        // Use the same color for the border without transparency
        const strokeColor = fillColor.substr(0, 7);

        return {
          clientNr: zone.clientNr,
          name: zone.name,
          paths: zone.location.coordinates[0].map((coord) => ({
            lat: coord[1], // latitude
            lng: coord[0], // longitude
          })),
          strokeColor,
          strokeOpacity: 1, // No transparency for the border
          strokeWeight: 1,
          fillColor,
          fillOpacity: 0.5, // 50% transparency for the fill
          enabled: zone.enabled,
          descriptiveName: zone.descriptiveName,
          numberOfCustomers: zone.numberOfCustomers,
          onClick: () => handleExportZone(zone.name),
        };
      });

      setMapPolygons(polygons);

    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };

  const handleDownloadZone = async (zoneName) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK+"/customers/queryall",
        { clientNr: user.clientNr, zoneName }, config
      );

      const data = response.data.map((customer) => ({ 
        customerNr: customer.customerNr,
        name: customer.name,
        street: customer.street,
        city: customer.city,
        state: customer.state,
        longitude: customer.street_lng,
        latitude: customer.street_lat,
      }));

      console.log("data CSV", data)

      downloadCSV(data, `${zoneName}_customers.csv`);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const handleEditZone = (zone) => {
    setSelectedZone(zone);
    setShowModal(true);
  };

  const handleExportZone = (zoneName) => {
    if (zoneName) {
      setSelectedZoneName(zoneName);
      setShowZoneDetails(true);
    } else {
      setSelectedZoneName(null);
      setShowZoneDetails(false);
    }
  };

  const handleCloseZoneDetails = () => {
    setShowZoneDetails(false);
  };

  const containerStyle = {
    width: "72%", // Adjust the width of the container
    height: "400px" // Adjust the height of the container
  };
  


  return (
    <div className="post">
      <div className="postWrapper">
        <div className="postTop">
          <span className="text-xl"> KML </span>
        </div>
        <div className="postCenter">
          <div className="mapContainer">
          <Map
            google={google}
            zoom={9} // Adjust the initial zoom level
            center={mapCenter} // Replace with your desired coordinates
            // initialCenter={mapCenter}
            containerStyle={containerStyle} 
          >
            {mapPolygons.map((polygon) => (
              <Polygon
                key={polygon.name}
                paths={polygon.paths}
                strokeColor={polygon.strokeColor}
                strokeOpacity={polygon.strokeOpacity}
                strokeWeight={polygon.strokeWeight}
                fillColor={polygon.fillColor}
                fillOpacity={polygon.fillOpacity}
                onClick={polygon.onClick}
              />
            ))}
          </Map>
          </div>
        </div>
        <div className="postBottom">
          <div className="postBottomLeft">
            
            <div>
            <FileUpload/>
            </div>
          </div>
        </div>
        <div id="zonesTitle"> Zones</div>
        <div className="tableContainer">
          <table className="zoneTable">
            <thead>
              <tr>
                <th className="zoneColumn">Name</th>
                <th className="customerColumn">Customer</th>
                <th className="toolsColumn"></th>
              </tr>
            </thead>
            <tbody>
            {mapPolygons.map((zone, index) => (
              <tr
              key={index}
              className={zone.enabled ? "zoneRowEnabled" : "zoneRowDisabled"}
              >
                <td className="zoneColumn">
                  <div className="zoneTableField">

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
    <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z" fill={zone.fillColor}/>
    <path d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z" fill={zone.fillColor}/>
  </svg>
                  <span className="nameField">{zone.descriptiveName}</span>
                  </div>
                  </td>
                <td className="customerColumn">
                <div className="zoneTableField"><span className="numberField">{zone.numberOfCustomers}</span>
                </div></td>
                <td>
                  <div className="tableButtonsContainer">
                    
                <svg className="zoneIcon"
                    onClick={() => handleEditZone(zone)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M14.0585 9.02L14.9785 9.94L5.91854 19H4.99854V18.08L14.0585 9.02ZM17.6585 3C17.4085 3 17.1485 3.1 16.9585 3.29L15.1285 5.12L18.8785 8.87L20.7085 7.04C21.0985 6.65 21.0985 6.02 20.7085 5.63L18.3685 3.29C18.1685 3.09 17.9185 3 17.6585 3ZM14.0585 6.19L2.99854 17.25V21H6.74854L17.8085 9.94L14.0585 6.19Z" fill="#1E1E1E"/>
  </svg>
                  
  <svg className="zoneIcon"
                    onClick={() => {
                      handleExportZone(zone.name);
                    }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z" fill="#1E1E1E"/>
  </svg>
  <svg className="zoneIcon"
                    onClick={() => handleDownloadZone(zone.name)}
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM5 18.5H19V20.5H5V18.5Z" fill="#1E1E1E"/>
  </svg>
                  
                    </div>
                </td>
              </tr>
            ))}
          </tbody>
          </table>

        </div>
      </div>
      {showZoneDetails && (
        <ZoneDetails
          zoneName={selectedZoneName}
          clientNr={user.clientNr}
          onClose={handleCloseZoneDetails}
        />
      )}
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          zone={selectedZone}
          onClose={() => {
            setSelectedZone(null);
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyATLZmi7Yw8jvwE3OikpScQ9hSwJsP2bXs", // Replace with your Google Maps API key
})(Post);
