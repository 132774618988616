import React from 'react'
import { useHistory } from 'react-router-dom'

export default function OTPconfirm({email, password}) {

    const history = useHistory();

    return (
        <div className="bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              <div className="font-semibold text-3xl">
                <p>Email Verification</p>
              </div>
            </div>
  
            <div>
              <form>
                <div className="flex flex-col space-y-24">
                  <div className="flex flex-row items-center justify-between mx-auto w-full">
              <div className="flex flex-row text-lg font-medium ">
                <p>Account {email} password succesfully updated! Please try to log in. </p>
              </div>
                   
                  </div>
  
                  <div className="flex flex-col space-y-5">
                    <div>
                      <a
                        onClick={() =>  history.push('/')}
                        className="flex flex-row cursor-pointer items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-green-900 border-none text-white text-lg shadow-sm"
                      >
                        Back to login
                      </a>
                    </div>
  
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    );
}
