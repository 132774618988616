import React, { useState, useEffect } from "react";
import axios from "axios";
import Switch from "react-switch";
import { useAlert } from '../../context/AlertContext'; 
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

import "./modal.css";

function Modal({ zone, onClose, setShowModal }) {
  const [descriptiveName, setDescriptiveName] = useState(zone.descriptiveName);
  const [isEnabled, setIsEnabled] = useState(zone.enabled); 

  const { showAlert } = useAlert();
  const { user, token } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleDescriptiveNameChange = (event) => {
    setDescriptiveName(event.target.value);
  };

  const handleIsEnableChange = (checked) => {
    console.log("CHECKBOX VALUE");
    console.log(checked);
    setIsEnabled(checked);
  };

  const handleUpdateZone = async () => {
    try {
      // Make an API call to update the zone details
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/zones/update", {
        clientNr: zone.clientNr,
        name: zone.name,
        descriptiveName: descriptiveName,
        enabled: isEnabled, 
      }, config);
      showAlert('New KML uploaded successfully!', 'success');
      setShowModal(false);


      // window.location.reload();
      // onClose(); // Close the modal after a successful update
    } catch (error) {
      console.error("Error updating zone:", error);
    }
  };

  console.log('Zone Data:', zone);

  return (
    <>

    <div className="modalDialog">
        <div className="top">
          <div className="left">Zone Details</div>
          <div className="close" onClick={onClose}>
            &times;
          </div>
        </div>
        <input
          type="text"
          id="name"
          value={zone.name}
          className="disabledInputName"
          disabled
          />
        <div className="middle">
          <label htmlFor="descriptiveName" className="middleText">Zone Name</label>
          <input
            type="text"
            id="descriptiveName"
            value={descriptiveName}
            onChange={handleDescriptiveNameChange}
            className="middleInput"
          />
        </div>
        <div className="switch-container">
        <div className="switch-wrapper">
            <Switch
            id="isEnabled"
            checked={isEnabled}
            onChange={handleIsEnableChange}
            height={20}
            width={36}
            onColor="#54807C"
            checkedIcon={false}
            uncheckedIcon={false}

            />
        </div>
        <label htmlFor="isEnabled" className="switch-label">Active Zone</label>
        </div>
        <div className="modalDialog-buttons">
          <button className="modalUpdatebutton" onClick={handleUpdateZone}>
            Save
          </button>
          <button className="modalcancelbutton" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default Modal;
