import "./topbar.css";
import { Link } from "react-router-dom";
import { useContext, useState, useRef, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import NewUserModal from "../newUserModal/NewUserModal";


export default function Topbar() {
  const modalRef = useRef();

  // Function to close modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowProfileModal(false);
    }
  };

  useEffect(() => {
    // Adding click event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
        // Cleanup the event listener
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  function handleProfileClick() {
    setShowProfileModal(true);
  }

  function handleLogout() {
    const confirmed = window.confirm(
      `Are you sure you want to sign out. Please make sure you have your credentials at hand in case you want to sign in again!`
    );
    if (confirmed) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      history.go(0);
    }
  }

  function handleCreateUser() {
    setShowProfileModal(false);
    setShowNewUserModal(true);
  }

  const { user } = useContext(AuthContext);

  return (
    <div className="topbarContainer" ref={modalRef}>
      <div className="topbarRight">
        <div className="topbarLinks mr-2" 
            onClick={handleProfileClick}
        >
          <div className="topBarLinksText">
            {showProfileModal && (
              <div className="profileModal bg-white shadow-md border py-3 pl-6 rounded-lg text-black font-medium w-40 h-auto flex justify-between items-start flex-col gap-3">
                {
                  user.isAdmin &&
                <div className="flex cursor-pointer" onClick={handleCreateUser}>
                  <img src="/assets/User.svg" alt="" className="pr-3" />
                  Create User
                  </div>

                }
                <div className="flex cursor-pointer" onClick={handleLogout}>
                  <img src="/assets/LogOut.svg" alt="" className="pr-3.5" />
                  Log Out
                </div>
                
              </div>
            )}
            <p className="topBarHi">Hi!</p>
            <span className="topbarLink">{user.username}</span>
          </div>
          <img
            src={"assets/person/noAvatar.png"}
            alt=""
            className="topbarImg"
            />
        </div>
      </div>
      {showNewUserModal && <NewUserModal setShowNewUserModal={setShowNewUserModal} />}
    </div>
  );
}
