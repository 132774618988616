import Sidebar from "../../components/sidebar/Sidebar";
import Feed from "../../components/feed/Feed";
// import Rightbar from "../../components/rightbar/Rightbar";
import "./home.css"

export default function Home() {
  return (
    <>
      <div className="homeContainer">
          <Sidebar />
          
        <div className="homeMainContainer"></div>
          <Feed/>
      </div>
    </>
  );
}
