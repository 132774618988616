import React, { useState, useEffect } from "react";
import axios from "axios";
import "./zoneDetails.css";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function ZoneDetails({ zoneName, clientNr, onClose }) {
  const [customers, setCustomers] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  
  const { user, token } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Este es opcional dependiendo de tus necesidades
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/customers/queryall",
          {
            clientNr: clientNr,
            zoneName: zoneName,
          }, config
        );
        setCustomers(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
        setCustomers([]);
      }
    };

    fetchData();
  }, [zoneName, clientNr]);

  const handleSearch = (e) => {
    setSearchFilter(e.target.value);
  };

  const clearFilter = () => {
    setSearchFilter("");
  };

  const downloadCSV = () => {
    const csvData = ["Customer Number,Name,Street,City,State,Longitude,Latitude"];
    customers.forEach((customer) => {
      csvData.push(
        `${customer.customerNr},"${customer.name}","${customer.street}","${customer.city}","${customer.state}",${customer.street_lng},${customer.street_lat}`
      );
    });
    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "customer_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Filter the customers based on the search filter
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchFilter.toLowerCase())
  );

  return (
    <div className="modalDialogFull">
        <div className="top">
          <div className="left">Zone Details</div>
          <div className="close" onClick={onClose}>
            &times;
          </div>
        </div>
        <div className="zoneDetailsNameContainer">
          <label id="zoneDetailsName" htmlFor="descriptiveName" className="middleText">Zone Name</label>
          <input
            type="text"
            id="name"
            value={zoneName}
            className="disabledInputName"
            disabled
            />
        </div>
        <div className="middle">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by customer name"
            value={searchFilter}
            onChange={handleSearch}
            className="modalSearch"
          />
          {searchFilter && (
            <button className="clear-filter" onClick={clearFilter}>
              Clear
            </button>
          )}
        </div>
        <div className="customer-list">
          <table className="CustomerDetailsTable">
            <thead>
              
              <tr>
                <th id="customerNameTh">Customer Number</th>
                <th>Customer Name</th>
                <th>Address</th>
                <th>Longitude</th>
                <th>Latitude</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer, index) => (
                <tr
                  key={customer.customerNr}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>{customer.customerNr}</td>
                  <td>{customer.name}</td>
                  <td>{customer.street}, {customer.city}, {customer.state}</td>
                  <td>{customer.street_lng}</td>
                  <td>{customer.street_lat}</td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
        </div>
       
        <div className="modalDialog-buttons">
        <button className="modalDownloadButton" onClick={downloadCSV}>
              Download File
            </button>
          <button className="modalcancelbutton" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
  );
}
