import { useState, useContext, useRef } from "react";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import OTPInput from "../../components/OTPInput/OTPInput";
import OTPconfirm from "../../components/OTPconfirm/OTPconfirm";


export default function ForgotPassword() {
  const clientNr = useRef();
  const gwokuToken = useRef();

  const [OTPsent, setOTPsent] = useState(false);
  const [OTPApproved, setOTPApproved] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [otpMail, setotpMail] = useState(null);
  const [otpPassword, setotpPassword] = useState(null);

  const email = useRef();
  const password = useRef();
  const { token } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };


  const handleClick = async (e) => {
    e.preventDefault();
    const otp = Math.floor(Math.random() * 9000 + 1000);
    console.log(otp);
    setOTP(otp);
    const result = await axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/users/reset-password", {
        OTP: otp,
        recipient_email: email.current.value,
      }, config)
      .then((res) => {
        setotpMail(email.current.value);
        setotpPassword(password.current.value);
        setOTPsent(true);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
        return;
      });
    console.log(result)
  };

  return (
    <div className="login">
      <div>
        <div className="loginWrapper">
          <div className="loginLogo">
            <img src="assets/ColorBg.png" alt="" className="loginLogoImg" />
          </div>
          {!OTPsent ? (
            <form className="loginBox" onSubmit={handleClick}>
              <div className="logintop">
                <h1> Forgot Password</h1>
              </div>
              <div className="logintop">
                <p>Enter your email and new password</p>
              </div>
              <div className="flex flex-col">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  required
                  className="loginInput"
                  id="emailInput"
                  ref={email}
                />
                <label>New Password</label>
                <input
                  type="password"
                  name="password"
                  required
                  className="loginInput"
                  id="passwordInput"
                  ref={password}
                />
              </div>
              <br></br>

              <div className="loginBottom"></div>
              <button className="loginButton" type="submit">
                Reset Password
              </button>
              <div></div>
            </form>
          ) : !OTPApproved ? (
            <OTPInput
              setOTPApproved={setOTPApproved}
              email={email.current.value}
              password={password.current.value}
              OTP={OTP}
            />
          ) : (
            <OTPconfirm email={otpMail} password={otpPassword} />
          )}
        </div>
      </div>
    </div>
  );
}
