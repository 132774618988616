import React, { useContext, useState } from "react";
import axios from "axios";
import "./fileupload.css";
import { AuthContext } from "../../context/AuthContext";
import UploadModal from "../uploadModal/uploadModal";
import { useAlert } from "../../context/AlertContext"; 


const PF = process.env.REACT_APP_PUBLIC_FOLDER;

function FileUpload(props) {

  const { showAlert } = useAlert();



  const { user, token } = useContext(AuthContext);
  const fileInputRef = React.createRef();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation dialog
  const [filename, setFilename] = useState(null);
  const [files, setFiles] = useState(null); // State for uploaded files

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleCancelUpload = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);
    setShowUploadModal(false)
    // Clear the file input when the user cancels
    setFilename(null);
    // Reset the files state
    setFiles(null);
  };

  const handleShowModal = () => {
    setShowUploadModal(true);
    console.log("Hola")
  }

  const handleFileChange = () => {
    const selectedFiles = fileInputRef.current.files;

    if (selectedFiles.length > 0) {
      const selectedFilename = selectedFiles[0].name;
      setFilename(selectedFilename);
      // Display confirmation dialog before uploading
      setConfirmationOpen(true);
      // Set the selected files in the state
      setFiles(selectedFiles);
    } else {
      return;
    }
  };

  const confirmUpload = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);

    // Set progress to 25% before uploading
    setProgress(25);
    setShowProgressBar(true); // Show the progress bar

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    console.log(formData)
    formData.append("clientNr", user.clientNr);

    axios.post(process.env.REACT_APP_CENTRAL_BACK + "/upload", formData, config)
      .then((response) => {
        const myfilename = files[0].name;
        // Set progress to 25% before calling API
        setProgress(25);
        callAPI(user.clientNr, myfilename);
      })
      .catch((error) => {
        showAlert(error.response.data, "error");
      });
  };

  function callAPI(clientNr, filename) {
    const originalbody = {
      clientNr: clientNr,
      url: process.env.REACT_APP_PUBLIC_FOLDER + "KML/" + filename,
    };
    const body = originalbody;
    axios.post(process.env.REACT_APP_CENTRAL_BACK + "/zones/add", body, config)
      .then((response) => {
        setProgress(50);
        callAPI2(clientNr, response.data.mapName);

      })
      .catch((error) => {
        showAlert(error.response.data, "error");
      });
  }

  function callAPI2(clientNr, mapName) {
    const originalbody = {
      clientNr: clientNr,
      mapName: mapName,
    };
    const body = originalbody;
    axios.post(process.env.REACT_APP_CENTRAL_BACK + "/zones/updategeo", body, config)
      .then((response) => {
        setProgress(75);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setProgress(90);
        setShowProgressBar(false);
        window.location.reload();
      })
      .catch((error) => {
        showAlert(error.response.data, "error");
      });
  }

 

  return (
    <div>
      <div className="uploadBox">
        {
          showProgressBar ?  (
            <div className="pl-6">
          <p className="text-xl pb-6 font-bold">Uploading Zones</p>
          <div className="progressContainer">
            <progress value={progress} max="100" className="progressBar" />
            <div className="font-semibold progressText">{`${Math.round(progress)}%`}</div>
          </div>
          </div>
        ) :
        (<div className="uploadelements" 
            onClick={handleShowModal} // Trigger file upload on change
            >
            Upload KML
        </div>)
      
      }
        {showUploadModal && (
          <UploadModal 
            setFilename= {setFilename} fileName = {filename} setConfirmationOpen={setConfirmationOpen} confirmationOpen={confirmationOpen} setFiles = {setFiles} confirmUpload={confirmUpload}
            onClose={() => {
              handleCancelUpload();
              setShowUploadModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default FileUpload;
