import React, {useState} from 'react'
import { useAlert } from '../../context/AlertContext'; // adjust the path as necessary
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

export default function NewUserModal({setShowNewUserModal}) {

    const { showAlert } = useAlert();
    const { user, token } = useContext(AuthContext);
    const config = {
        headers: {
            Authorization: `Bearer ${token}`, 
        }
    }


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        clientNr: '',
        odooUrl: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // validate password
        if (formData.password !== formData.confirmPassword) {
            console.error('Passwords do not match');
            //use alerts
            showAlert('Passwords do not match', 'error');
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/auth/register', {
                username: formData.firstName + ' ' + formData.lastName,
                email: formData.email,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                clientNr: formData.clientNr,
                odooUrl: formData.odooUrl
            }, config).then(resp => {
                showAlert('User created successfully!', 'success');
            }).catch((error) => {
                showAlert(error.response.data.message, error.response.data.status);
            }
            ).finally(() => {
                setShowNewUserModal(false);
            });
            console.log('response', response);
        } catch (error) {
            console.error('Error sending data', error);
            // Handle error
            showAlert(error.response.data.message, 'error');
        }
    };

    function onClose() {
        setShowNewUserModal(false);
      }

  return (
    <div className="modalDialogFull" id="new_user_modal">
    <div className="top">
      <div className="left">Create User</div>
      <div className="close" onClick={onClose}>
        &times;
      </div>
    </div>
    <div className="zoneDetailsNameContainer pt-3">
        <form action="" className='flex flex-col gap-10'>
            <div className='row flex justify-between gap-5'>
                <div className="flex flex-col gap-3 w-[50%]">
                    <label htmlFor="descriptiveName" className="middleText">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        placeholder='First Name'
                        value={formData.firstName}
                        onChange={handleChange}
                        className="formField"
                    />
                    </div>
                    <div  className="flex flex-col gap-3 w-[50%]">
                    <label htmlFor="descriptiveName" className="middleText">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        placeholder='Last Name'
                        value={formData.lastName}
                        onChange={handleChange}
                        className="formField"
                    />
                    </div>
                

            </div>
            <div className='row flex justify-between gap-5'>
                <div className="flex flex-col gap-3 w-[33%]">
                    <label htmlFor="email" className="middleText">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder='Email'
                        value={formData.email}
                        onChange={handleChange}
                        className="formField"
                    />
                </div>
                <div className="flex flex-col gap-3 w-[33%]">
                    <label htmlFor="password" className="middleText">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder='Password'
                        value={formData.password}
                        onChange={handleChange}
                        className="formField"
                    />
                </div>
                <div className="flex flex-col gap-3 w-[33%]">
                    <label htmlFor="confirmPassword" className="middleText">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        placeholder='Confirm Password'
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className="formField"
                    />
                </div>
            </div>
            <div className='row flex justify-between gap-5'>
                <div className="flex flex-col gap-3 w-[32%]">
                    <label htmlFor="clientNr" className="middleText">Client Nr</label>
                    <input
                        type="text"
                        id="clientNr"
                        placeholder='Client Nr'
                        value={formData.clientNr}
                        onChange={handleChange}
                        className="formField"
                    />
                </div>
                <div className="flex flex-col gap-3 w-[68%]">
                    <label htmlFor="odooUrl" className="middleText">OdooUrl</label>
                    <input
                        type="text"
                        id="odooUrl"
                        placeholder='Odoo URL'
                        value={formData.odooUrl}
                        onChange={handleChange}
                        className="formField"
                    />
                </div>
            </div>
        </form>
    </div>
   
    <div className="modalDialog-buttons">
    <button className="modalDownloadButton" onClick={handleSubmit}>
          Save
        </button>
      <button className="modalcancelbutton" onClick={onClose}>
        Cancel
      </button>
    </div>
  </div>
  )
}
