import axios from "axios";
import { useRef } from "react";
import "./register.css";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function Register() {
  const formRef = useRef(null);

  const username = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const history = useHistory();
  const { user: currentuser } = useContext(AuthContext);
  const clientNr = currentuser.clientNr;

  const { token } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };



  const handleClick = async (e) => {
    e.preventDefault();
    if (passwordAgain.current.value !== password.current.value) {
      passwordAgain.current.setCustomValidity("Passwords don't match!");
    } else {
      const user = {
        clientNr: clientNr,
        username: username.current.value,
        email: email.current.value,
        password: password.current.value,
      };
      const body = user;
      try {
        await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/auth/register", body, config);
        alert("User has been registered");
        formRef.current.reset(); // clear the form fields
        history.push("/register");
      } catch (err) {
        console.log("return from api call");
        console.log(err.response.data);
        alert(err.response.data);
        }
    }
  };

  return (
    
    <div className="user">
      <div>
        <div className="userRight">
          <form ref={formRef} className="userBox" onSubmit={handleClick}>
          <div className = "top">  
          <h1>Register users</h1>
          </div>
          <br></br>
          <label>Username</label>
            <input
              required
              ref={username}
              className="userInput"
            />
            <label>Email</label>
            <input
              required
              ref={email}
              className="userInput"
              type="email"
              autocomplete="new-password"
            />
            <label>Password</label>
            <input
              required
              ref={password}
              className="userInput"
              type="password"
              minLength="6"
              autocomplete="new-password"
            />
            <label>Password Again</label>
            <input
              required
              ref={passwordAgain}
              className="userInput"
              type="password"
            />
            <button className="userButton" type="submit">
              Register
            </button>
            <button className="userCancelButton"  onClick={() => history.push("/")}>Cancel</button>
          </form>
        </div>
      </div>
    </div>
  
  );
}
