import React, { useEffect } from 'react';
import { useAlert } from '../../context/AlertContext'; 
import FileUpload from "../fileupload/FileUpload";
import "./incompleteMap.css";

export default function IncompleteMap({ updateStatus }) {
  const { showAlert } = useAlert();

  useEffect(() => {
    if (updateStatus === 'error') {
      showAlert('An error occurred during the map update process.', 'error');
    } else if (updateStatus === 'in progress') {
      showAlert('The map update is currently in progress.', 'success');
    }
  }, [updateStatus]);

  return (
    <div className="noMap">
      <div className="noMapWrapper">
        <div className="incompleteMapTop">
          {updateStatus === 'error' && (
            <>
              <span className='text-xl'> Map Update Error </span>
              {/* You can add additional error-specific content here */}
            </>
          )}
          {updateStatus === 'in progress' && (
            <>
              <span className='text-xl'> Map Update In Progress </span>
             <p className='text-lg p-16 pl-0'>
                Map calculations are currently in progress. This may take a few minutes. Please wait for the process to complete before uploading a new map.
             </p>
            </>
          )}
        </div>
        <div className='incompleteMapMiddle'>
          {updateStatus === 'error' && (
            <FileUpload /> // Assuming you want to allow file re-upload on error
          )}
          {/* Additional content based on updateStatus */}
        </div>
      </div>
    </div>
  );
}
